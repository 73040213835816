import { Directive, Input, OnInit, ViewContainerRef, TemplateRef, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CurrentNavigationService, CurrentTenantRegister } from '@parashift/shared/services';

@Directive({
  selector: '[checkFeatureFlag]',
  standalone: true
})
export class CheckFeatureFlagDirective implements OnInit {

  @Input() checkFeatureFlag: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Element>,
    private currentTenantRegister: CurrentTenantRegister,
    private currentNavigationService: CurrentNavigationService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.checkFeatureFlagPermission();
  }

  checkFeatureFlagPermission() {
    const current_tenant = this.currentTenantRegister.tenant;

    if (current_tenant && current_tenant.feature_flags && Array.isArray(current_tenant.feature_flags)) {
      if (!current_tenant.feature_flags.find(flag => flag === this.checkFeatureFlag)) {
        this.viewContainer.clear();
        return;
      }
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  listenForNavigationChanges() {
    this.currentNavigationService.navigationChanged$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(changed => {
      if (changed) {
        this.checkFeatureFlagPermission();
      }
    });
  }
}
